<template>
  <div class="njServe">
    <div class="topImg"></div>
    <Title
      class="njTitle"
      njTitle="NjImg"
      typeOf="njImgs"
      :title="address + '数字农机'"
    ></Title>

    <div class="ContentBox">
      <div class="ContentLeft">
        <MkContent titleSrcName="njTitle" titleName="服务情况">
          <LeftCont :propOptions="jztOption"></LeftCont>
        </MkContent>
        <MkContent
          titleSrcName="njTitle"
          titleName="作业类型"
          style="margin-top: 20px"
        >
          <div class="njTopBox" style="padding: 0">
            <LeftCont :propOptions="ChartOption"></LeftCont>
            <LeftCont :propOptions="PieOption"> </LeftCont>
            <el-select
              @change="(year, type) => yearChangeData(year, 1)"
              v-model="currentTime"
            >
              <el-option
                v-for="item in TimeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </MkContent>
      </div>

      <!-- 中间部分 -->
      <div class="middleBox">
        <div class="midImg">
          <img :src="midImg" alt="" style="width: 100%; height: 100%" />
        </div>

        <div class="middleTop">
          <div class="topFont">总作业量</div>
          <div v-for="(v, index) in workload" class="topCount" :key="index">
            {{ v }}
          </div>
          <div class="topFont">亩</div>
        </div>
        <div class="ContentMiddle">
          <MidBox
            v-for="v in MidDatafw"
            :key="v.id"
            :boxData="v"
            style="margin: 0 companyW(8vw)"
            @changeMaskd="changeMas"
          >
          </MidBox>
        </div>
      </div>

      <div class="ContentLeft">
        <MkContent titleSrcName="njTitle" titleName="服务类别">
          <LeftCont :propOptions="njzl"></LeftCont>
        </MkContent>
        <MkContent
          titleSrcName="njTitle"
          titleName="补贴类型"
          style="margin-top: 20px"
        >
          <div class="njTopBox" style="padding: 0">
            <LeftCont :propOptions="btlxOption"></LeftCont>
            <LeftCont :propOptions="btPieOption"> </LeftCont>
            <el-select
              v-model="currentTime1"
              @change="(year, type) => yearChangeData(year, 2)"
            >
              <el-option
                v-for="item in TimeOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </MkContent>
      </div>
    </div>
    <BottomNav
      :navData="btData"
      class="BottomNav"
      njTitle="navBottomImg"
      typeOf="njImgs"
    ></BottomNav>
    <MaskBox
      v-on="$listeners"
      @getTabal="getTabal"
      :tableTotal="tableTotal"
      :modelTitle="modelTitle"
      :changeMask="showMask"
      :mainTable="TableTitle"
      showNav="false"
      @getMask="closeMask"
      :NavList="[]"
      :TanleContent="tableData"
    >
    </MaskBox>
  </div>
</template>

<script>
//引入接口文件
import {
  getStatus,
  getJobOrderType,
  subsidy,
  getJobOrderSubsidy,
  getMain,
  jobOrder
} from '../../api/njApi/njzy';
import { getIndexType } from '@/api/njApi/njzl.js';
import { getBasicInfo } from '../../api/njfw/njfw';
import LeftCont from './components/zyfw/ZyfwLeft.vue';
import MaskBox from '@/views/njall/components/MaskTool1.vue';
import Title from '@/views/drying/components/title.vue';
// 底部导航栏
import BottomNav from '@/views/njall/components/BottomNav.vue';
import { btData } from '@/views/njall/mock/njJson.js';
import MkContent from '@/views/njall/components/ModelBg.vue';
import * as njlxmock from '@/views/njall//components/js/njlxmock.js';
// 中间每个小标题 src\views\njall\components/js/njlxmock.js
import MidBox from '@/views/njall/components/MiddleBox.vue';
import * as zyfwMock from '@/views/njall/components/js/zylxMock.js';
import * as mockData from '@/views//njall/components/js/njJson.js';
import * as toolData from '@/views/njall/components/js/zyfwToolMock.js';
export default {
  components: {
    MaskBox,
    MidBox,
    Title,
    BottomNav,
    MkContent,
    LeftCont
  },
  data () {
    return {
      //数据条数
      tableTotal: 0,
      //弹窗分页
      pagData: {
        pageNum: 1,
        pageSize: 10,
        jobType: ''
      },
      //弹窗标题
      modelTitle: '',
      ...toolData,
      //作业类型数据
      zylxData: [],
      //补贴类型数据
      btlxData: [],
      MidDatafw: [],
      TableTitle: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'jobObj', label: '作业对象', width: '100' },
        { id: 3, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 4, prop: 'jobNum', label: '田块面积', width: '100' },
        { id: 5, prop: 'jobPrice', label: '作业价格', width: '100' },
        { id: 6, prop: 'jobPriceAmount', label: '作业总额', width: '100' },
        { id: 7, prop: 'orderFinishTime', label: '作业时间', width: '100' },
        {
          id: 8,
          prop: 'JobTrajectory',
          label: '作业轨迹',
          width: '100',
          btn: true
        },
        { id: 7, prop: 'orderEvaluate', label: '评价', width: '100', pj: true }
      ],
      //耕整完成
      TableTitle1A: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmer', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 5, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 6, prop: 'jobTractorTel', label: '机手电话', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'zyTime', label: '作业时间', width: '150' },
        { id: 11, btn: true, label: '作业路径', width: '100' },
        { id: 12, prop: 'orderEvaluate', label: '评价', width: '100', pj: true }
      ],
      //机插完成
      TableTitle2A: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmer', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'pesticidesSource', label: '秧苗来源', width: '100' },
        { id: 5, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 6, prop: 'jobTractorTel', label: '机手电话', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'zyTime', label: '作业时间', width: '150' },
        { id: 10, btn: true, label: '作业路径', width: '100' },
        { id: 11, prop: 'orderEvaluate', label: '评价', width: '100', pj: true }
      ],
      //机收完成
      TableTitle3A: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmer', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'jobObj', label: '作物类型', width: '100' },
        { id: 5, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 6, prop: 'jobTractorTel', label: '机手电话', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'zyTime', label: '作业时间', width: '150' },
        { id: 11, btn: true, label: '作业路径', width: '100' },
        { id: 12, prop: 'orderEvaluate', label: '评价', width: '100', pj: true }
      ],
      //植保完成
      TableTitle4A: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmer', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'jobObj', label: '作物类型', width: '100' },
        { id: 5, prop: 'pesticidesSource', label: '农药来源', width: '80' },
        { id: 6, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 7, prop: 'jobTractorTel', label: '机手电话', width: '80' },
        { id: 8, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 9, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 10, prop: 'appoint', label: '作业时间', width: '150' },
        { id: 11, btn: true, label: '作业路径', width: '100' },
        { id: 12, prop: 'orderEvaluate', label: '评价', width: '100', pj: true }
      ],
      address: '',
      showMask: false,
      ...mockData,
      ...zyfwMock,
      ...njlxmock,
      btData: btData,
      currentTime: '2023',
      currentTime1: '2023',
      TimeOptions: [
        {
          value: '2023',
          label: '2023'
        },
        {
          value: '2022',
          label: '2022'
        }
      ],
      TimeOptions1: [
        {
          value: '2023',
          label: '2023'
        },
        {
          value: '2022',
          label: '2022'
        }
      ],
      midImg: ''
    };
  },
  created () {
    this.address = localStorage.getItem('address');
    //获取接口数据

    this.getData();
  },
  mounted () {
    this.getmidImg();
    this.getType();
  },
  methods: {
    async getmidImg () {
      const { data } = await getBasicInfo();

      this.midImg = data[0].img;
    },
    //获取弹窗数据
    async getTabal (e, type) {
      let zt = false;
      console.log(e);
      this.pagData.pageNum = e;

      await jobOrder(this.pagData).then(res => {
        if (res.code == 1) {
          if (res.data.records.length) {
            this.tableTotal = res.data.total;
            this.tableData = res.data.records.map((item, index) => {
              return Object.assign(item, {
                serialNum: index + 1,
                appoint: item.appointmentStart
                  ? `${ item.appointmentStart.slice(0, 10) }${
                    item.startTime
                  }~${ item.appointmentEnd.slice(0, 10) }${
                    item.endTime
                  }`.replace(/-/g, '/')
                  : '',
                farmer: item.farmerName
                  ? `${ item.farmerName }${
                    item.jobFieldName ? `-${ item.jobFieldName }` : ''
                  }`
                  : '',
                jobPrice: item.jobPrice ? `${ item.jobPrice }元/亩` : '',
                jobNum: item.jobNum ? `${ item.jobNum }亩` : '',
                seedingPrice: item.seedingPrice
                  ? `${ item.seedingPrice }元/盘`
                  : '',
                seedingNum: item.seedingNum ? `${ item.seedingNum }盘` : '',
                orderFinishTime2: item.orderFinishTime
                  ? `${ item.orderFinishTime.slice(0, 10) }`
                  : '',
                zyTime: item.orderFinishTime
                  ? `${ item.orderTime.slice(
                    0,
                    10
                  ) }~${ item.orderFinishTime.slice(0, 10) }`.replace(/-/g, '/')
                  : '',
                dryingCount: item.dryingCount ? `${ item.dryingCount }吨` : '',
                outTower: item.outTower ? `${ item.outTower }吨` : ''
              });
            });
          }
          zt = true;
        } else {
          zt = false;
        }
      });
      return zt;
    },
    async getType () {
      const { data } = await getIndexType();

      this.njzl.xAxis.data = data.map(item => {
        return item.car_type.split(',').join();
      });
      this.njzl.series[0].data = data.map(item => {
        return item.COUNT;
      });

      const res = data
        .map(item => {
          return item.COUNT;
        })
        .reduce((item, prev) => {
          return (item += prev);
        }, 0);
      this.njzl.series[0].name = `总数：${ res }台`;
    },
    //时间下拉框选中
    currentTimeChange (e) {
      // console.log(e,'选中数据');
    },
    //切换年份改变数据
    yearChangeData (e, type) {
      if (type === 1) {
        let fwqkData = [
          { value: 25, name: '耕整', percen: 25 },
          { value: 30, name: '机插', percen: 30 },
          { value: 10, name: '植保', percen: 10 },
          { value: 35, name: '机收', percen: 35 }
        ];
        //找到e对应的元素索引
        let index = this.zylxData.findIndex(item => {
          return item.year == e;
        });
        this.PieOption.title.text = `${ this.zylxData[index].amount }亩`;
        fwqkData[0].value = this.zylxData[index].tillage;
        fwqkData[1].value = this.zylxData[index].transplant;
        fwqkData[2].value = this.zylxData[index].protection;
        fwqkData[3].value = this.zylxData[index].reap;
        fwqkData[0].percen = Math.floor(
          (this.zylxData[index].tillage / this.zylxData[index].amount) * 100
        );
        fwqkData[1].percen = Math.floor(
          (this.zylxData[index].transplant / this.zylxData[index].amount) * 100
        );
        fwqkData[2].percen = Math.floor(
          (this.zylxData[index].protection / this.zylxData[index].amount) * 100
        );
        fwqkData[3].percen = Math.floor(
          (this.zylxData[index].reap / this.zylxData[index].amount) * 100
        );

        this.PieOption.legend.formatter = function (name) {
          var target, unit;
          for (var i = 0, l = fwqkData.length; i < l; i++) {
            if (fwqkData[i].name == name) {
              target = fwqkData[i].value;
              unit = fwqkData[i].unit;
            }
          }
          return `{a| ${ name }}{b|${ target }%}`;
        };
        this.PieOption.series[0].data = fwqkData;
      } else {
        let fwqkData = [
          { value: 25, name: '耕整作业', percen: 25 },
          { value: 30, name: '机插作业', percen: 30 },
          { value: 35, name: '植保作业', percen: 10 },
          { value: 10, name: '机收作业', percen: 35 }
        ];
        //找到e对应的元素索引
        let index = this.btlxData.findIndex(item => {
          return item.year == e;
        });
        this.btPieOption.title.text = `${ this.zylxData[index].amount }元`;
        fwqkData[0].value = this.btlxData[index].tillage;
        fwqkData[1].value = this.btlxData[index].transplant;
        fwqkData[2].value = this.btlxData[index].protection;
        fwqkData[3].value = this.btlxData[index].reap;
        fwqkData[0].percen = Math.floor(
          (this.btlxData[index].tillage / this.zylxData[index].amount) * 100
        );
        fwqkData[1].percen = Math.floor(
          (this.btlxData[index].transplant / this.zylxData[index].amount) * 100
        );
        fwqkData[2].percen = Math.floor(
          (this.btlxData[index].protection / this.zylxData[index].amount) * 100
        );
        fwqkData[3].percen = Math.floor(
          (this.btlxData[index].reap / this.zylxData[index].amount) * 100
        )
        ;(this.btPieOption.legend.formatter = function (name) {
          var target, unit, newName;
          for (var i = 0, l = fwqkData.length; i < l; i++) {
            if (fwqkData[i].name == name) {
              target = fwqkData[i].value;
              unit = fwqkData[i].unit;
              newName = name.slice(0, 2);
            }
          }
          return `{a| ${ newName }}{b|${ target }%}`;
        }),
        (this.btPieOption.series[0].data = fwqkData);
      }
    },
    //获取接口数据
    async getData () {
      await getMain().then(res => {
        if (res.code == 1) {
          res.data.forEach(item => {
            if (item.job_type === '耕整作业') {
              console.log(item);
              this.MidDatafw[0].data = item.job_num;
            }
          });
          res.data.forEach(item => {
            if (item.job_type === '机插作业') {
              console.log(item);
              this.MidDatafw[1].data = item.job_num;
            }
          });
          res.data.forEach(item => {
            if (item.job_type === '机收作业') {
              console.log(item);
              this.MidDatafw[2].data = item.job_num;
            }
          });
          res.data.forEach(item => {
            if (item.job_type === '植保作业') {
              console.log(item);
              this.MidDatafw[3].data = item.job_num;
            }
          });
        }
      });

      await getStatus().then(res => {
        if (res.code == 1) {
          //对内
          let dn = (res.data.selfCount / res.data.total) * 100;
          //对外
          let dw = (res.data.outCount / res.data.total) * 100;
          //总计

          this.workload = res.data.total.toString().split('');
          this.jztOption.series[0].max = res.data.total;
          this.jztOption.series[0].data = [
            {
              id: 'title',
              value: res.data.outCount,
              name: `${ Math.floor(dw ? dw : 0) }%`,
              tit: '对外服务'
            },
            {
              id: 'contt',
              value: res.data.selfCount,
              name: `${ Math.floor(dn ? dn : 0) }%`,
              tit: '自我服务'
            }
          ].sort(function (a, b) {
            return a.value - b.value;
          });
          this.jztOption.title.text = `${ this.workload.join('') }亩`;
          // this.jztOption.title.subtext = res.data[2].is_self
        }
      });

      await getJobOrderType().then(res => {
        if (res.code == 1) {
          this.zylxData = res.data;
          this.ChartOption.xAxis.data = res.data.map(item => {
            return item.year;
          });
          this.TimeOptions = res.data.map(item => {
            return {
              value: item.year,
              label: item.year
            };
          });
          // this.fwqkData =
          this.ChartOption.series[0].data = res.data.map(item => {
            return item.tillage;
          });
          this.ChartOption.series[1].data = res.data.map(item => {
            return item.transplant;
          });
          this.ChartOption.series[2].data = res.data.map(item => {
            return item.protection;
          });
          this.ChartOption.series[3].data = res.data.map(item => {
            return item.reap;
          });
          //获取今年年份
          this.currentTime = new Date().getFullYear();
          this.yearChangeData(this.currentTime, 1);

          // console.log(date,'datedatedatedate')
        }
      });

      await subsidy().then(res => {
        if (res.code == 1) {
          // debugger
          let total = res.data[0].superiorMoney + res.data[0].localMoney;
          //上级补贴
          let dn = (res.data[0].superiorMoney / total) * 100;
          // //当地补贴
          let dw = (res.data[0].localMoney / total) * 100;
          // //总计
          let workload = total.toString().split('');
          this.btqkOption.series[0].max = total;
          this.btqkOption.series[0].data = [
            {
              id: 'title',
              value: res.data[0].localMoney,
              name: `${ Math.floor(dw ? dw : 0) }%`,
              tit: '当地补贴'
            },
            {
              id: 'contt',
              value: res.data[0].superiorMoney,
              name: `${ Math.floor(dn ? dn : 0) }%`,
              tit: '上级补贴'
            }
          ].sort(function (a, b) {
            return a.value - b.value;
          });
          this.btqkOption.title.text = `${ workload.join('') }元`;
          // this.btqkOption.title.subtext = res.data[2].type
        }
      });

      await getJobOrderSubsidy().then(res => {
        if (res.code == 1) {
          this.btlxData = res.data;

          this.btlxOption.xAxis.data = res.data.map(item => {
            return item.year;
          });
          this.TimeOptions1 = res.data.map(item => {
            return {
              value: item.year,
              label: item.year
            };
          });

          // this.fwqkData =
          this.btlxOption.series[0].data = res.data.map(item => {
            return item.tillage;
          });
          this.btlxOption.series[1].data = res.data.map(item => {
            return item.transplant;
          });
          this.btlxOption.series[2].data = res.data.map(item => {
            return item.protection;
          });
          this.btlxOption.series[3].data = res.data.map(item => {
            return item.reap;
          });

          //获取今年年份
          this.currentTime1 = new Date().getFullYear();
          this.yearChangeData(this.currentTime1, 2);

          // console.log(date,'datedatedatedate')
        }
      });
    },
    showDetail (id, typeofs) {
      //
      switch (id) {
      case 1:
        this.modelTitle = '耕整作业';
        this.TableTitle = this.TableTitle1A;
        break;
      case 2:
        this.modelTitle = '机插作业';
        this.TableTitle = this.TableTitle2A;
        break;
      case 3:
        this.modelTitle = '机收作业';
        this.TableTitle = this.TableTitle3A;
        break;
      case 4:
        this.modelTitle = '植保作业';
        this.TableTitle = this.TableTitle4A;
        break;
      default:
        break;
      }
      this.pagData.jobType = this.modelTitle;
      this.getTabal(1, id).then(err => {
        console.log(err, 'err');
        if (err) {
          this.showMask = true;
        }
      });
    },
    closeMask (item) {
      this.showMask = item;
    },
    changeMas (params) {
      console.log(params, 'params');
      this.showDetail(params.id, params.typeof);
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.midImg {
  width: companyW(940vw);
  height: companyH(1000vh);

  position: absolute;
  margin-left: companyW(10vw);
}
.njServe {
  position: relative;
  background: #1d2531;

  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;

  .topImg {
    width: 100%;
    height: companyH(300vw);
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    background-image: url(../../assets/njImgs/topBackGround.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }

  .njTitle {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  .ContentBox {
    max-width: companyW(1880vw);
    display: flex;
    margin: 0 auto;
    justify-content: space-between;

    .ContentMiddle {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: companyH(40vh) companyW(12vw);

      .topCount {
        background: rgba(0, 53, 178, 0.3);
        border: 2px solid;
        border-image: linear-gradient(0deg, #24c4ff, #0057c2) 10 10;
        border-radius: 3px;
      }
    }
  }

  .BottomNav {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.njTopBox {
  box-sizing: border-box;
  width: companyW(460vw);
  margin-top: companyH(-12vh);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgba($color: #03171f, $alpha: 0.7);
  position: relative;
}

.el-select {
  position: absolute;
  right: companyW(36vw);
  top: companyH(290vh);
  z-index: 3;

  ::v-deep .el-input {
    width: companyW(76vw);
  }
}

::v-deep .el-input__inner {
  height: companyH(27.4vh);
  background: rgba(6, 40, 62, 0.9);
  border-radius: 0;
  border: #00def0 companyW(1vw) solid;
  font-size: companyH(11.35vh);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #e1eaea;
}

::v-deep .el-input__suffix-inner {
  position: relative;
  top: companyH(9vh);
}

:global(.el-select-dropdown) {
  width: companyW(76vw);
  background: rgba(6, 40, 62, 0.9);
}

:global(.el-select-dropdown__item span) {
  font-size: companyH(13vh);
}

.middleBox {
  display: flex;
  flex-direction: column;
  flex: 1;

  .middleTop {
    display: flex;
    align-items: center;
    margin: companyH(40vh) auto;
    margin-bottom: companyH(10vh);
    z-index: 3;

    .topFont {
      font-size: companyH(24vh);
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      margin: 0 companyW(6vw);
    }

    .topCount {
      width: companyW(36vw);
      background: rgba(0, 53, 178, 0.3);
      border: companyH(2vh) solid;
      border-image: linear-gradient(0deg, #24c4ff, #0057c2) 10 10;
      border-radius: companyW(3vw);
      text-align: center;
      line-height: companyH(48vh);
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      margin: 0 companyW(4vw);
    }
  }
}
</style>
